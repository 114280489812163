<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="businessLetters"
    >
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-if="column.date" v-slot="props">
            {{ props.row[column.field] | formatDate }}
          </template>
          <template v-else v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        field="isNotice"
        label="Informare"
        sortable
        centered
        v-slot="props"
      >
        <span>
          {{ props.row.isNotice ? "DA" : "NU" }}
        </span>
      </b-table-column>
      <b-table-column
        field="responseDate"
        label="Data limita solutionare"
        sortable
        centered
        v-slot="props"
      >
        <span :class="getComputedStyle(props.row)">
          {{ props.row.deadline | formatDate }}
        </span>
      </b-table-column>
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-right is-flex-wrap-nowrap">
          <router-link
            :to="{ name: 'businessLetter.edit', params: { id: props.row.id } }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>
          <b-button
            :disabled="!isAdmin"
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </b-button>
        </div>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare date...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nu exista date&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from "@/components/ModalBox";
import { openBlankPage } from "@/helpers/utils";
import { isNilOrEmpty } from "@/helpers/utils";
import moment from "moment";

export default {
  name: "BusinessLettersTable",
  components: { ModalBox },
  props: {
    businessLetters: {
      type: Array,
      default: null,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      clients: [],
      isLoading: false,
      paginated: true,
      perPage: 20,
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          numeric: true,
          visible: false,
        },
        {
          field: "issuer",
          label: "Emitent",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "externalRegistrationNumber",
          label: "Nr.Inreg. extern",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "externalRegistrationDate",
          label: "Data Inreg. extern",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: false,
          visible: true,
          date: true,
        },
        {
          field: "internalRegistrationNumber",
          label: "Nr.Inreg. intern",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "internalRegistrationDate",
          label: "Data Inreg. intern",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: false,
          visible: true,
          date: true,
        },
        {
          field: "department",
          label: "Departament",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "description",
          label: "Continut (pe scurt)",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "numberOfDaysToRespond",
          label: "Nr. zile solutionare",
          numeric: false,
          sortable: false,
          centered: true,
          searchable: false,
          visible: true,
          date: false,
        },
        {
          field: "responseDescription",
          label: "Continut raspuns (pe scurt)",
          numeric: false,
          sortable: false,
          centered: true,
          searchable: true,
          visible: true,
          date: false,
        },
        {
          field: "responseDate",
          label: "Data solutionare",
          numeric: false,
          sortable: true,
          centered: true,
          searchable: false,
          visible: true,
          date: true,
        },
      ],
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return (
          this.trashObject.internalRegistrationNumber +
          " din data " +
          new Date(
            this.trashObject.internalRegistrationDate
          ).toLocaleDateString()
        );
      }

      return null;
    },
  },
  mounted() {
    this.isLoading = false;
    if (this.businessLetters && this.businessLetters > 0) {
      if (this.businessLetters.length > this.perPage) {
        this.paginated = true;
      }
    }
  },
  methods: {
    getComputedStyle(businessLetter) {
      const currentDate = moment(new Date()).startOf();
      const deadline = moment(businessLetter.deadline).startOf();

      if (
        currentDate.isAfter(deadline, "day") &&
        businessLetter.responseDate == null &&
        !businessLetter.isNotice
      )
        return "tag is-danger";

      const oneDayBeforeDeadLine = moment(businessLetter.deadline)
        .startOf()
        .subtract(1, "days");

      if (
        currentDate.isSame(oneDayBeforeDeadLine, "day") &&
        businessLetter.responseDate == null &&
        !businessLetter.isNotice
      )
        return "tag is-warning";

      if (
        currentDate.isSame(deadline, "day") &&
        businessLetter.responseDate == null &&
        !businessLetter.isNotice
      ) {
        return "tag is-warning";
      }

      if (businessLetter.responseDate != null && !businessLetter.isNotice)
        return "tag is-success";
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    downloadFile(file) {
      if (!isNilOrEmpty(file)) {
        openBlankPage(file);
      }
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteBusinessLetter", this.trashObject.id);
      this.$buefy.snackbar.open({
        message: "Inregistrea a fost stearsa.",
        queue: false,
      });
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>

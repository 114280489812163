<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button
        class="button is-primary"
        :disabled="!isAdmin"
        @click="handleAddBusinessLetter"
      >
        Inregistrare noua
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Inregistrari"
        icon="file-multiple"
      >
        <businessLetters-table
          :businessLetters="businessLetters"
          @handleDeleteBusinessLetter="handleDeleteBusinessLetter"
          :isAdmin="isAdmin"
        />
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import BusinessLetters from "@/services/businessLetters.service";
import BusinessLettersTable from "@/components/businessLetters/businessLetters-table";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
const _ = require("lodash");

export default {
  name: "BusinessLetters",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    BusinessLettersTable,
  },
  data() {
    return {
      businessLetters: [],
      isAdmin: this.$auth.user.email === "registratura@primarie.online",
    };
  },
  created() {
    this.getAllBusinessLetters().then((response) => {
      this.businessLetters = response;

      for (var i = 0; i < this.businessLetters.length; i++) {
        if (this.businessLetters[i].isNotice) {
          this.businessLetters[i].deadline = null;
          this.businessLetters[i].numberOfDaysToRespond = null;
        }
      }

      this.businessLetters = _.orderBy(
        this.businessLetters,
        "modifiedAt",
        "desc"
      );
    });
  },
  methods: {
    async getAllBusinessLetters() {
      const { handleGetBusinessLetters } = BusinessLetters;
      const response = await handleGetBusinessLetters();
      const { data } = response;
      return data;
    },
    async handleAddBusinessLetter() {
      this.$router.push({
        path: "/businessLetters/add",
      });
    },
    async handleDeleteBusinessLetter(id) {
      const { handleDeleteBusinessLetter } = BusinessLetters;
      const response = await handleDeleteBusinessLetter(id);
      const { data, status } = response;

      if (status == "200") {
        const array = this.businessLetters;
        const index = this.businessLetters.findIndex((prop) => prop.id === id);
        this.businessLetters.splice(index, 1);
      } else {
      }
    },
  },
  computed: {
    titleStack() {
      return ["Documente", "Documente"];
    },
    heroRouterLinkTo() {
      return { name: "/businessLetters/add" };
    },
  },
};
</script>

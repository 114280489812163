export default Object.freeze({
  getAllBusinessLetters: {
    GET: () => {
      return `businessLetters`;
    },
  },
  addBusinessLetter: {
    GET: "businessLetters",
  },
  getBusinessLetter: {
    GET: (id) => {
      return `businessLetters/${id}`;
    },
  },
  editBusinessLetter: {
    GET: (id) => {
      return `businessLetters/${id}`;
    },
  },
  deleteBusinessLetter: {
    GET: (id) => {
      return `businessLetters/${id}`;
    },
  },
});

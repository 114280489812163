import businessLettersEndpoints from "./endpoints/businessLetters-endpoints";
import apiConfig from "../config/api";
import http from "./http";

const {
  getAllBusinessLetters,
  addBusinessLetter,
  getBusinessLetter,
  deleteBusinessLetter,
  editBusinessLetter,
} = businessLettersEndpoints;
const { baseURL } = apiConfig;

const BusinessLetters = {
  async handleGetBusinessLetters() {
    const getAllBusinessLettersUrl = `${baseURL}${getAllBusinessLetters.GET()}`;
    return await http
      .get(
        getAllBusinessLettersUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleAddBusinessLetter(businessLetter) {
    const getAddBusinessLettertUrl = `${baseURL}${addBusinessLetter.GET}`;
    return await http
      .post(
        getAddBusinessLettertUrl,
        {
          issuer: businessLetter.issuer,
          isNotice: businessLetter.isNotice,
          externalRegistrationNumber: businessLetter.externalRegistrationNumber,
          externalRegistrationDate: businessLetter.externalRegistrationDate,
          internalRegistrationNumber: businessLetter.internalRegistrationNumber,
          internalRegistrationDate: businessLetter.internalRegistrationDate,
          department: businessLetter.department,
          description: businessLetter.description,
          numberOfDaysToRespond: businessLetter.numberOfDaysToRespond,
          deadline: businessLetter.deadline,
          responseDescription: businessLetter.responseDescription,
          responseDate: businessLetter.responseDate,
          username: businessLetter.username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleEditBusinessLetter(businessLetter) {
    const getEditBusinessLetterUrl = `${baseURL}${editBusinessLetter.GET(
      businessLetter.id
    )}`;
    return await http
      .put(
        getEditBusinessLetterUrl,
        {
          id: businessLetter.id,
          issuer: businessLetter.issuer,
          isNotice: businessLetter.isNotice,
          externalRegistrationNumber: businessLetter.externalRegistrationNumber,
          externalRegistrationDate: businessLetter.externalRegistrationDate,
          internalRegistrationNumber: businessLetter.internalRegistrationNumber,
          internalRegistrationDate: businessLetter.internalRegistrationDate,
          department: businessLetter.department,
          description: businessLetter.description,
          numberOfDaysToRespond: businessLetter.numberOfDaysToRespond,
          deadline: businessLetter.deadline,
          responseDescription: businessLetter.responseDescription,
          responseDate: businessLetter.responseDate,
          username: businessLetter.username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleGetBusinessLetter(id) {
    const getBusinessLetterUrl = `${baseURL}${getBusinessLetter.GET(id)}`;
    return await http
      .get(
        getBusinessLetterUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async handleDeleteBusinessLetter(id) {
    const getDeleteBusinessLetterUrl = `${baseURL}${deleteBusinessLetter.GET(
      id
    )}`;
    return await http
      .delete(
        getDeleteBusinessLetterUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default BusinessLetters;
